import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons'

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    return (
        <Link to={url} className="post-card">
            <div className="post-card-left">
                {post.published_at_pretty}
            </div>
            <h2 className="post-card-title">{post.title}</h2>
            <div className="post-card-right">
                {post.featured && <FontAwesomeIcon className="icon" icon={faStar} size="sm" />}
                {readingTime}
            </div>
            <FontAwesomeIcon className="post-card-icon" icon={faChevronRight} size="sm" />
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
